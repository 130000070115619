
import * as Sentry from '@sentry/browser';



Sentry.init({
  dsn: "https://2167231570d74901b73bddb0cebf0be8@sentry.io/6536",
  
  release: 'd6f5241c08ba2ab7dfe4d743afdeffd4951f96fd',
  
  linesOfContext: 500, 
  autoSessionTracking: false,
  environment: 'staging',
  ignoreErrors: [ 'DOMException: WRONG_DOCUMENT_ERR', 'Non-Error promise rejection captured', 'EvalError' ]
});


window.Sentry = Sentry

